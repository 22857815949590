@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('./fonts/fonts.css');

html {
  font-size:62.5%;
  font-family:'Avenir Next LT Pro Regular','Open Sans', sans-serif;
  background:#f2f3f6;
}

@media screen and (min-width: 641px) {
  html {
    font-size: 59%;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 62.5%;
  }
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
  color: #50595e;
  font-family:'Avenir Next LT Pro Regular','Open Sans', sans-serif;
}
button{
  font-family: 'Avenir Next LT Pro Regular','Open Sans', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  font-size: 16px;
  color: 'gray';
}

svg {
  overflow: visible;
  
}

text {
  fill: #71777C;
  color: #71877C;
  font-size: 0.80rem;
}
text.textOnChartPoint{
  font-size:0.80rem;
  fill: #71777C;
  color: #71877C;
  font-weight:bold;
}

text.textOnChartPointNew{
  font-size:1rem;
  fill: #71777C;
  font-weight:bold;
}

text.textOnChartPointNewWhite{
  font-size:1rem;
  fill: #fcfcfc;
  font-weight:bold;
}

text.textOnChartPointBar{
  user-select: none;
  background-color: black;
  font-size:1rem;
  fill: black;
  color: #ffffff;
  font-weight:bold;
}

.container.hasHighlight .textOnChartPointBar:hover {
  filter: saturate(100%);
  opacity: 1;
}

.axis.xAxis.trimester .tick text{
  transform: rotate(90deg) translate(17px, -23px);
  font-size:0.70rem;
}


.newCharts text{
  font-size:1rem;
}

.newChartsBar text{
  font-size:0.8rem;
}

.subtitle text {
  text-decoration: underline;
  font-size: 12px;
}

.axis .domain,
.axis.xAxis line {
  display: none;
}

.tick line{
  opacity: 0.2;
  stroke-dasharray: 5,5;
}

.domain{
  visibility: hidden;
}

a:-webkit-any-link,a{
  color: #22a6f5;
  text-decoration:none;
}
a:visited{
  color:#22a6f5 ;
  text-decoration:none;
}

.new-institution-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 1.2; /* Adjust line height for better readability */
}