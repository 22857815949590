// SCSS Variables for Material-UI breakpoints
$breakpoint-xs: 0px; // xs
$breakpoint-sm: 600px; // sm
$breakpoint-md: 960px; // md
$breakpoint-lg: 1280px; // lg
$breakpoint-xl: 1920px; // xl

.chartcontainer {
    min-height: 500px;
}

.containerBaseCard {
    margin: 10px;
    padding: 0px 10px;
    border: 1px solid black;
    border-radius: 2px;

    @media (max-width: $breakpoint-sm) {
        margin: 10px 0px !important;
    }
}

.subtitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: 10px;
}

.longDescription {
    width: 100%;
}

.typeContainer {
    text-align: center;
    font-size: 2.3rem;
    margin-bottom: 0;
    padding-left: 10px;
    width: fit-content;
}

.typeParent {
    display: flex;
    justify-content: center;
}

.footerContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: 10px;
    width: calc(100% - 10px);
}

.containerFooterCase {
    margin: 10px 10px 0px;
    border: 1px solid #e4e9ec; // Hay que agregar la paleta de colores, este es theme.palette.secondary.lighter
    border-radius: 2px 2px 0 0;

    @media (max-width: $breakpoint-sm) {
        margin: 10px 0px 0px !important
    }
}

.graphContainer {
    height: 450px;

    @media (max-width: $breakpoint-sm) {
        height: 370px !important;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        height: 68vw !important;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
        height: 375px !important;
    }
}

iframe {
    overflow: hidden !important
}

/* @media (max-width:599.95px) {
    .container {}
} */